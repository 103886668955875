import { Box } from "@mui/material"
import { useContextSelector } from 'use-context-selector'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { OcurrencesPageContext } from "@pages/ocurrences/context";

export const Filters = () => {
    const dateEnd = useContextSelector(OcurrencesPageContext, s => s.dateEnd)
    const dateStart = useContextSelector(OcurrencesPageContext, s => s.dateStart)

    const setDateEnd = useContextSelector(OcurrencesPageContext, s => s.setDateEnd)
    const setDateStart = useContextSelector(OcurrencesPageContext, s => s.setDateStart)

    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <DatePicker value={dateStart} onChange={setDateStart} />
            <DatePicker value={dateEnd} onChange={setDateEnd} />
        </Box>
    )
}