/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import moment, { Moment } from 'moment'
import { OcurrencesPageContext } from './context'
import { OcurrencesPageControllerProps } from './types'
import { OcurrenceApi } from '@services/api/ocurrence_api'
import { Ocurrence, OcurrencePagination } from '@type/ocurrence'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const OcurrencesPageController = (
  props: OcurrencesPageControllerProps
): JSX.Element => {
  const api = useApi()
  const [total, setTotal] = useState("0")
  const [dateEnd, setDateEnd] = useState<Moment | null>(moment())
  const [dateStart, setDateStart] = useState<Moment | null>(moment().subtract(2, 'days'))
  const [loading, setLoading] = useState<boolean>(true)
  const [ocurrences, setOcurrences] = useState<Ocurrence[]>([])

  const handleLoadData = useCallback(() => {
    if (dateStart && dateEnd) {
      setLoading(true)

      api
        .instanceOf<OcurrenceApi>(OcurrenceApi)
        .list_ocurrences(dateStart.format('DD/MM/yyyy HH:mm:ss'), dateEnd.format('DD/MM/yyyy HH:mm:ss'))
        .then((data: OcurrencePagination) => {
          setTotal(data.total)
          setOcurrences(data.ocorrencias)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [dateStart, dateEnd])

  useEffect(() => {
    handleLoadData()
  }, [dateStart, dateEnd])

  const state = useMemo(
    () => ({
      loading,
      total,
      ocurrences,
      dateEnd,
      dateStart,
      setDateStart,
      setDateEnd
    }),
    [
      loading,
      total,
      ocurrences,
      dateEnd,
      dateStart,
      setDateStart,
      setDateEnd
    ]
  )

  return (
    <OcurrencesPageContext.Provider value={state}>
      {props.children}
    </OcurrencesPageContext.Provider>
  )
}
