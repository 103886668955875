export function Logo({
  fill,
  stroke,
  width,
}: {
  fill: string
  stroke: string
  width: string
}) {
  return (
    <svg
      version="1.1"
      id="Camada_1"
      fill={fill}
      stroke={stroke}
      width={width}
      x="0px"
      y="0px"
      viewBox="0 0 800 595"
    >
      <g transform="translate(0.000000,595.000000) scale(0.100000,-0.100000)">
        <path
          d="M3700,5755.3c-961-57-1821-326-2495-781c-354-238-696-585-882-893c-156-259-249-507-300-795c-24-139-24-480,0-620
		c24-136,71-309,114-419c396-1010,1538-1767,3003-1990c573-87,1147-87,1720,0c1465,223,2607,980,3003,1990c43,110,90,283,114,419
		c24,140,24,481,0,620c-51,288-144,536-300,795c-186,308-528,655-882,893c-617,416-1393,678-2255,762
		C4383,5752.3,3846,5763.3,3700,5755.3z M1993,4047.3c18-7,44-26,59-42c21-23,695-1496,850-1858c40-93,34-139-25-201
		c-49-50-97-64-158-46c-61,19-91,52-138,155l-44,96h-604h-604l-43-93c-65-139-132-186-226-158c-75,22-120,82-120,159
		c0,32,92,244,426,984c234,519,435,953,446,965C1858,4058.3,1931,4074.3,1993,4047.3z M3305,4047.3c19-8,45-25,58-38
		c12-13,177-367,367-786c332-737,345-763,357-735c8,15,161,357,342,758c205,454,340,742,358,761c34,38,97,59,147,50
		c44-8,104-59,122-103c20-48,20-1908,0-1956c-20-50-78-95-131-103c-59-8-130,25-160,76c-19,34-20,55-25,593l-5,558l-260-576
		c-280-623-287-634-368-649c-50-9-110,9-145,43c-19,20-105,200-265,553c-130,287-247,548-261,578l-25,55v-549c-1-535-2-551-22-592
		c-58-120-230-119-289,2c-20,39-20,63-20,991v950l23,43c16,30,37,51,67,67C3220,4065.3,3257,4067.3,3305,4047.3z M6727,4050.3
		c40-15,73-48,94-94c17-38,19-81,19-757v-718h43c99-1,168-63,175-159c3-46,0-62-20-91c-37-55-82-80-144-80h-54v-47
		c0-71-18-123-54-159c-118-118-297-17-284,160l3,41l-592,5c-578,5-592,5-619,26c-67,50-93,138-60,202c15,28,1247,1553,1315,1626
		C6596,4056.3,6665,4074.3,6727,4050.3z"
        />
        <path d="M1920,3463.3c-92-199-440-976-437-979c2-2,206-2,454-1l450,3l-227,502C1943,3468.3,1932,3489.3,1920,3463.3z" />
        <path
          d="M6130,2970.3c-201-247-371-459-379-469c-13-20-9-20,370-20h384v470c0,259-2,470-5,469C6497,3420.3,6331,3217.3,6130,2970.3
		z"
        />
      </g>
    </svg>
  )
}
