import { Grid, Typography } from '@mui/material'
import { TypeOptions } from './types'
import { Labels } from './components/Labels'
import { Filters } from './components/Filters'
import { DashboardPageContext } from './context'
import { Dashboard } from '@containers/Dashboard'
import { useContextSelector } from 'use-context-selector'

export function DashboardPageView() {
  const value = useContextSelector(DashboardPageContext, (s) => s.value)
  const type = useContextSelector(DashboardPageContext, (s) => s.type)
  const filter = useContextSelector(DashboardPageContext, (s) => s.filter)
  const filters = useContextSelector(DashboardPageContext, (s) => s.filters)

  const getFilter = (name: string) => {
    const available = filters.filter(f => f.nome.toUpperCase().indexOf(name.toUpperCase()) !== -1)
    return available.find(a => a.nome.indexOf(filter) !== -1)
  }

  const barLabels = type === TypeOptions.hour && filter === "24" ? <Labels /> : undefined

  return (
    <>
      <Filters />

      <Typography variant="h4">Todos os sócios</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Dashboard
            type={type}
            value={value}
            period={filter}
            chartType="bar"
            title="Menções"
            filter={getFilter("todos")}
            labels={barLabels}
            description="Análise do volume de menções em redes sociais, blogs e portais no período"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Dashboard
            type="OCORRENCIASPORQUALIFICACAO"
            value={value}
            chartType="doughnut"
            title="Sentimento"
            filter={getFilter("todos")}
            description="Análise de sentimento de menções nas redes"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Dashboard
            type="OCORRENCIASPORQUALIFICACAOPORSERVICO"
            value={value}
            chartType="pie"
            title="Redes sociais"
            filter={getFilter("todos")}
            description="Análise da distribuição de menções nas redes"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Dashboard
            type="PUBLICADORESMAISINFLUENTES"
            value={value}
            title="Principais Vozes"
            chartType="table"
            filter={getFilter("todos")}
            description="Publicadores mais influentes com base no alcance"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Dashboard
            value={value}
            type="TAGSMAISUTILIZADAS"
            chartType="horizontalBar"
            title="Menções por assunto"
            filter={getFilter("todos")}
            description="Distribuição das menções em temas, ordenadas por alcance"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Dashboard
            type="NUVEMTERMOSMAISCITADOS"
            value={value}
            chartType="wordCloud"
            title="Termos Mais Citados"
            filter={getFilter("todos")}
            description="Palavras mais recorrentes nas menções aos sócios"
          />
        </Grid>
      </Grid>

      <Typography variant="h4">Lemann</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Dashboard
            type={type}
            value={value}
            period={filter}
            title="Menções"
            chartType="bar"
            filter={getFilter("lemann")}
            labels={barLabels}
            description="Análise do volume de menções em redes sociais, blogs e portais no período"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Dashboard
            type="OCORRENCIASPORQUALIFICACAO"
            value={value}
            chartType="doughnut"
            title="Sentimento"
            filter={getFilter("lemann")}
            description="Análise de sentimento de menções nas redes"
          />
        </Grid>
      </Grid>

      <Typography variant="h4">Telles</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Dashboard
            type={type}
            value={value}
            period={filter}
            title="Menções"
            chartType="bar"
            filter={getFilter("telles")}
            labels={barLabels}
            description="Análise do volume de menções em redes sociais, blogs e portais no período"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Dashboard
            type="OCORRENCIASPORQUALIFICACAO"
            value={value}
            chartType="doughnut"
            title="Sentimento"
            filter={getFilter("telles")}
            description="Análise de sentimento de menções nas redes"
          />
        </Grid>
      </Grid>


      <Typography variant="h4">Sicupira</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Dashboard
            type={type}
            value={value}
            period={filter}
            title="Menções"
            chartType="bar"
            filter={getFilter("sicupira")}
            labels={barLabels}
            description="Análise do volume de menções em redes sociais, blogs e portais no período"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Dashboard
            type="OCORRENCIASPORQUALIFICACAO"
            value={value}
            chartType="doughnut"
            title="Sentimento"
            filter={getFilter("sicupira")}
            description="Análise de sentimento de menções nas redes"
          />
        </Grid>
      </Grid >
    </>
  )
}
