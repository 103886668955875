import { Button } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

export const getColumns = (onClick: (key: string) => void): GridColDef[] => [
  {
    field: 'key', headerName: 'Documento', width: 700, renderCell: (params: GridRenderCellParams<any, Date>) => (
      <Button
        size="small"
        color='secondary'
        onClick={() => onClick(params.row["key"])}
      >
        {params.row["key"]}
      </Button>
    )
  },
  { field: 'updated_at', headerName: 'Data do documento', width: 350 },
]
