import { ApiAction } from '../../hooks/api/action'
import { Report } from '@type/report'

export class ReportApi extends ApiAction {
  async list(): Promise<Report[]> {
    const response = await this.http.get<any>(`/reports?v=20240331101701`)
    return response.data as Report[]
  }

  async get(key: string): Promise<string> {
    const response = await this.http.get<any>(`/reports/${key}`)
    return response.data.url as string
  }
}
