import { Box } from '@mui/material'
import { Logo } from '@components/Logo'
import AppBar from '@mui/material/AppBar'
import { Typography } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import { NavLink } from 'react-router-dom'
import Configurator from '@containers/Configurator'

import {
  useMaterialUIController,
} from '@context'

function Navbar() {
  const [controller] = useMaterialUIController()

  const {
    darkMode,
    transparentSidenav,
    whiteSidenav
  } = controller

  const companyLogo = (transparentSidenav && !darkMode) || whiteSidenav ? (
    <Logo fill="black" stroke="black" width="2em" />
  ) : (
    <Logo fill="white" stroke="white" width="2em" />
  )

  let textColor = '#FFFFFF'

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark'
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit'
  }

  return (
    <>
      <AppBar
        position='absolute'
        color="primary"
      >
        <Toolbar sx={{ justifyContent: 'space-between', padding: 4 }}>
          <Box component={NavLink} to="/" display="flex" flexDirection="column" gap={4}>
            {companyLogo}

            <Box display="flex" flexDirection="column" gap={2}>
              <Typography
                variant="h3"
                fontWeight="medium"
                color={textColor}
                display="flex"
                flexDirection="row"
              >
                análise digital<Typography color="secondary" fontWeight="medium" variant="h3">.</Typography>
              </Typography>

              <Typography
                variant="h6"
                fontWeight="medium"
                color="secondary"
              >
                / LTS
              </Typography>
            </Box>
          </Box>

          <Configurator />
        </Toolbar>
      </AppBar>

    </>
  )
}

export default Navbar
