function configs(labels, datasets, label) {
  return {
    type: 'bar',
    data: {
      labels,
      datasets,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            boxWidth: 12,
          },
        },
        datalabels: {
          display: false
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 60,
          grid: {
            display: true,
            drawBorder: false,
            color: function (context) {
              if (label) {
                if (context.tick.value === 50) {
                  return '#FF3131'
                } else if (context.tick.value === 40) {
                  return '#FFDE59'
                } else if (context.tick.value === 30) {
                  return '#20FF87'
                } else if (context.tick.value === 10) {
                  return '#5CE1E6'
                }

                return 'transparent'
              }

              return '#d6d6d6'
            },
          },
        }
      }
    }
  }
}

export default configs
