/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { Report } from '@type/report'
import { ReportsPageContext } from './context'
import { ReportsPageControllerProps } from './types'
import { ReportApi } from '@services/api/report_api'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const ReportsPageController = (
  props: ReportsPageControllerProps
): JSX.Element => {
  const api = useApi()
  const [loading, setLoading] = useState<boolean>(true)
  const [reports, setReports] = useState<Report[]>([])

  const handleLoadData = useCallback(() => {
    api
      .instanceOf<ReportApi>(ReportApi)
      .list()
      .then((data: Report[]) => {
        setReports(data)
      })
      .finally(() => {
        setLoading(false)
      })

  }, [])

  useEffect(() => {
    handleLoadData()
  }, [])

  const state = useMemo(
    () => ({
      loading,
      reports,
    }),
    [
      loading,
      reports
    ]
  )

  return (
    <ReportsPageContext.Provider value={state}>
      {props.children}
    </ReportsPageContext.Provider>
  )
}
