import Router from './router'
import { useEffect } from 'react'
import theme from '@assets/theme'
import Navbar from '@containers/Navbars'
import themeDark from '@assets/theme-dark'
import { CssBaseline } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'

import {
  Chart as ChartJS,
} from 'chart.js'

import {
  useMaterialUIController,
} from './context'

export default function App() {
  const { pathname } = useLocation()
  const [controller] = useMaterialUIController()

  const {
    direction,
    darkMode,
  } = controller

  if (controller.darkMode) {
    ChartJS.defaults.color = '#ffffff';
  } else {
    ChartJS.defaults.color = 'gray';
  }

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction)
  }, [direction])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    if (document.scrollingElement) document.scrollingElement.scrollTop = 0
  }, [pathname])


  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Navbar />
      <Router />
    </ThemeProvider>
  )
}
