import { GridColDef } from '@mui/x-data-grid'

export const columns: GridColDef[] = [
  { field: 'titulo', headerName: 'Título', width: 250 },
  { field: 'conteudo', headerName: 'Conteúdo', width: 350 },
  {
    field: 'link',
    headerName: 'Link',
    sortable: false,
    width: 250,
  },
  {
    field: 'data',
    headerName: 'Data',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 250,
  },
]
