import { useApi } from '@hooks/api'
import Card from '@mui/material/Card'
import { getColumns } from './columns'
import { DataGrid } from '@mui/x-data-grid'
import { ReportApi } from '@services/api/report_api'
import { useContextSelector } from 'use-context-selector'
import { ReportsPageContext } from '@pages/reports/context'
import { Box, CircularProgress, Typography } from '@mui/material'

function Table() {
  const api = useApi()

  const openReport = async (key: string) => {
    const url = await api
      .instanceOf<ReportApi>(ReportApi)
      .get(key)

    setTimeout(() => {
      window.open(url, '_blank');
    })
  }

  const columns = getColumns(openReport)

  const loading = useContextSelector(ReportsPageContext, (s) => s.loading)

  const reports = useContextSelector(
    ReportsPageContext,
    (s) => s.reports
  )

  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            Relatários
          </Typography>
          <Box display="flex" alignItems="center" lineHeight={0}>
            <Typography variant="body2" fontWeight="regular" color="text">
              <strong>{reports.length} encontrados</strong>
            </Typography>
          </Box>
        </Box>
        {loading && <CircularProgress />}
      </Box>

      <Box sx={{ padding: 3 }}>
        <DataGrid
          rows={reports}
          getRowId={(row) => row.key}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </Box>
    </Card>
  )
}

export default Table
