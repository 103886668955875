import { Filter } from '@type/dashboard'
import { ApiAction } from '../../hooks/api/action'

export class DashboardApi extends ApiAction {
  async list_filters(): Promise<Filter[]> {
    const response = await this.http.get<any>('/api/rest/filtros/listar?monitoramentoId=684')
    return response.data.filtros as Filter[]
  }

  async generate_dash(filterId: string): Promise<string> {
    const response = await this.http.get<any>(`/api/rest/graficos/gerarDash?monitoramentoId=684&filtroId=${filterId}`)
    return response.data.conteudo as string
  }

  async get_data(dashId: string, type: string): Promise<string> {
    const response = await this.http.get<any>(`/api/rest/graficos/dados?codigo=${dashId}&tipo=${type}`)
    return response.data.conteudo as string
  }
}
