/* eslint-disable no-array-constructor */

import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import { Box, Button, Typography } from '@mui/material'
import { CircularProgress } from '@mui/material'
import configs from '@containers/ReportsBarChart/configs'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import moment from 'moment'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function ReportsBarChart({
  color,
  title,
  period,
  description,
  dashboard,
  labels,
  loading,
  error,
  handleLoadData
}) {
  let { label, values } = dashboard && dashboard.label && dashboard.values ? dashboard : { label: [], values: [] }

  if (period === "24" && values[0][0].indexOf('h') !== -1) {
    let index = 0;
    const currentHour = moment().format('H')
    for (const i in values) {
      const hours = values[i][0].replace('h', '').split('-')
      if (currentHour >= hours[0] && currentHour <= hours[1]) {
        index = parseInt(i) + 1;
      }
    }

    console.log(index)
    const valuesBegin = values.slice(index, values.length)
    const valuesEnd = values.slice(0, index)
    values = [...valuesBegin, ...valuesEnd]
  }

  const xLabels = values?.map((value) => {
    return value[0]
  })

  const barColors = [
    'rgba(75, 192, 192, 0.2)',
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(201, 203, 207, 0.2)'
  ]

  const borderColor = [
    'rgb(75, 192, 192)',
    'rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(201, 203, 207)'
  ]

  const datasets = label?.slice(1, label.length)?.map((value, index) => {
    return {
      label: value,
      data: values?.map((value) => {
        return value[index + 1]
      }),
      backgroundColor: barColors[index],
      borderColor: borderColor[index],
      tension: 0.4,
      borderWidth: 1,
      borderSkipped: false,
    }
  })


  const { data, options } = configs(xLabels, datasets, labels)

  return (
    <Card sx={{ height: '100%' }}>
      <Box padding="1rem">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box pt={2} pb={1} px={1}>
            <Typography variant="h6" textTransform="capitalize">
              {title}
            </Typography>
            <Typography color="text" variant="body2" fontWeight="light">
              {description}
            </Typography>
            <Divider />
            {labels}
          </Box>
          {loading && <CircularProgress />}
        </Box>

        {useMemo(
          () => (
            <Box
              display="flex"
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              height="30rem"
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              {error ? <Box>
                <Typography>Erro ao carregar os dados</Typography>
                <Button onClick={handleLoadData}>Recarregar</Button>
              </Box> : datasets.length > 0 ? <Bar data={data} options={options} redraw /> : <Typography> {loading ? 'Carregando...' : 'Sem dados no período'}</Typography>}
            </Box>
          ),
          [datasets, data, options, color, loading, error, handleLoadData]
        )}
      </Box>
    </Card>
  )
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: 'white',
  description: '',
}

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'white',
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ReportsBarChart
