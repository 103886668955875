function configs(labels, datasets) {
  return {
    type: 'doughnut',
    data: {
      labels,
      datasets,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            boxWidth: 12,
          }
        },
        datalabels: {
          formatter: (value, ctx) => {
            const datapoints = ctx.chart.data.datasets[0].data
            const total = datapoints.reduce((total, datapoint) => total + datapoint, 0)
            const percentage = value / total * 100
            const result = percentage.toFixed(2);
            return result > 5 ? result + "%" : "";
          },
          color: '#ffffff',
        }
      },
    }
  }
}

export default configs
