import { OcurrencesPageView } from './view'
import { OcurrencesPageProps } from './types'
import { OcurrencesPageController } from './controller'

export const OcurrencesPage = (props: OcurrencesPageProps): JSX.Element => {
  return (
    <OcurrencesPageController {...props}>
      <OcurrencesPageView />
    </OcurrencesPageController>
  )
}
