/* eslint-disable no-array-constructor */

import { useMemo } from 'react'
import PropTypes from 'prop-types'
import configs from './configs/index'
import { Pie } from 'react-chartjs-2'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import { Box, Button, Typography } from '@mui/material'
import { CircularProgress } from '@mui/material'

import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

function ReportsPieChart({
  color,
  title,
  description,
  dashboard,
  labels,
  loading,
  error,
  handleLoadData
}) {
  const { values } = dashboard || { label: [], values: [] }

  const resources = values?.map(v => {
    return {
      label: v[0],
      value: v.slice(1, v.length).reduce((a, b) => a + b, 0)
    }
  }).sort(function (a, b) {
    return b.value - a.value;
  })

  const xLabels = resources.map((resource) => {
    return resource.label
  })

  const datasets = [{
    label: 'Resultado',
    data: resources?.map((resource) => {
      return resource.value
    }),
  }]

  const { data, options } = configs(xLabels, datasets)

  return (
    <Card sx={{ height: '100%' }}>
      <Box padding="1rem">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box pt={2} pb={1} px={1}>
            <Typography variant="h6" textTransform="capitalize">
              {title}
            </Typography>
            <Typography color="text" variant="body2" fontWeight="light">
              {description}
            </Typography>
            <Divider />
            {labels}
          </Box>
          {loading && <CircularProgress />}
        </Box>

        {useMemo(
          () => (
            <Box
              display="flex"
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              height="30rem"
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              {error ? <Box>
                <Typography>Erro ao carregar os dados</Typography>
                <Button onClick={handleLoadData}>Recarregar</Button>
              </Box> : xLabels.length > 0 ? <Pie data={data} options={options} redraw /> : <Typography> {loading ? 'Carregando...' : 'Sem dados no período'}</Typography>}
            </Box>
          ),
          [xLabels, data, options, color, loading, error, handleLoadData]
        )}
      </Box>
    </Card>
  )
}

// Setting default values for the props of ReportsBarChart
ReportsPieChart.defaultProps = {
  color: 'white',
  description: '',
}

// Typechecking props for the ReportsBarChart
ReportsPieChart.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'white',
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ReportsPieChart
