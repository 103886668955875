import { DashboardProps } from './types'
import { DashboardPageView } from './view'
import { DashboardPageController } from './controller'

export const Dashboard = (props: DashboardProps): JSX.Element => {
  return (
    <DashboardPageController {...props}>
      <DashboardPageView {...props} />
    </DashboardPageController>
  )
}
