import './index.css'
import App from './App'
import React from 'react'
import '@fontsource/poppins'
import { enableMapSet } from 'immer'
import ReactDOM from 'react-dom/client'
import { ApiProvider } from './hooks/api'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { StorageProvider } from './hooks/storage'
import { MaterialUIControllerProvider } from './context'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

enableMapSet()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={1}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <BrowserRouter>
        <StorageProvider>
          <ApiProvider apiBaseURL={process.env.REACT_APP_API_URL}>
            <MaterialUIControllerProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <App />
              </LocalizationProvider>
            </MaterialUIControllerProvider>
          </ApiProvider>
        </StorageProvider>
      </BrowserRouter>
    </SnackbarProvider>
  </React.StrictMode >
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
