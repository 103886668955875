import { Box } from "@mui/material";
import Table from "./components/Table";
import { Filters } from "./components/Filters";

export function OcurrencesPageView() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Filters />
      <Table />
    </Box>
  );
}