import { Box } from '@mui/material'

export const Labels = () => {
  return (
    <Box
      sx={{ display: { md: 'flex', xs: 'none' }, gap: 2, alignItems: 'center' }}
    >
      <Box
        sx={{
          color: '#5CE1E6',
          backgroundColor: '#13303B',
          borderRadius: 2,
          pt: 1,
          pb: 1,
          pl: 1.5,
          pr: 1.5,
          fontSize: 10,
        }}
      >
        <b>NORMAL</b>
      </Box>
      <Box
        sx={{
          color: '#20FF87',
          backgroundColor: '#162F36',
          borderRadius: 2,
          pt: 1,
          pb: 1,
          pl: 1.5,
          pr: 1.5,
          fontSize: 10,
        }}
      >
        <b>ATENÇÃO</b>
      </Box>
      <Box
        sx={{
          color: '#FFDE59',
          backgroundColor: '#1D2221',
          borderRadius: 2,
          pt: 1,
          pb: 1,
          pl: 1.5,
          pr: 1.5,
          fontSize: 10,
        }}
      >
        <b>ALERTA</b>
      </Box>
      <Box
        sx={{
          color: '#FF3131',
          backgroundColor: '#251E28',
          borderRadius: 2,
          pt: 1,
          pb: 1,
          pl: 1.5,
          pr: 1.5,
          fontSize: 10,
        }}
      >
        <b>CRÍTICO</b>
      </Box>
    </Box>
  )
}
