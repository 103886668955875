/* eslint-disable no-array-constructor */

import { useMemo } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import ReactWordcloud from 'react-wordcloud'
import { Box, Button, Typography } from '@mui/material'
import { CircularProgress } from '@mui/material'

const options = {
  enableTooltip: true,
  deterministic: false,
  fontFamily: "impact",
  fontSizes: [24, 120],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 1,
  rotations: 3,
  rotationAngles: [0, 90],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function WordCloudChart({
  color,
  title,
  description,
  dashboard,
  labels,
  loading,
  error,
  handleLoadData
}) {
  const { values } = dashboard || { label: [], values: [] }

  const words = values?.slice(1, values.length - 1).map(v => {
    return {
      text: v[0],
      value: v[1]
    }
  })

  return (
    <Card sx={{ height: '100%' }}>
      <Box padding="1rem">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box pt={2} pb={1} px={1}>
            <Typography variant="h6" textTransform="capitalize">
              {title}
            </Typography>
            <Typography color="text" variant="body2" fontWeight="light">
              {description}
            </Typography>
            <Divider />
            {labels}
          </Box>
          {loading && <CircularProgress />}
        </Box>

        {useMemo(
          () => (
            <Box
              display="flex"
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              height="30rem"
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              {error ? <Box>
                <Typography>Erro ao carregar os dados</Typography>
                <Button onClick={handleLoadData}>Recarregar</Button>
              </Box> : words.length > 0 ? <ReactWordcloud words={words} options={options} /> : <Typography> {loading ? 'Carregando...' : 'Sem dados no período'}</Typography>}
            </Box>
          ),
          [words, color, loading, error, handleLoadData]
        )}
      </Box>
    </Card>
  )
}

// Setting default values for the props of ReportsBarChart
WordCloudChart.defaultProps = {
  color: 'white',
  description: '',
}

// Typechecking props for the ReportsBarChart
WordCloudChart.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'white',
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default WordCloudChart
