/* eslint-disable no-array-constructor */
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import { DataGrid } from '@mui/x-data-grid'
import Divider from '@mui/material/Divider'
import { CircularProgress } from '@mui/material'
import { Box, Button, Typography } from '@mui/material'

function ReportsTable({
  color,
  title,
  description,
  dashboard,
  labels,
  loading,
  error,
  handleLoadData
}) {
  const { label, values } = dashboard || { label: [], values: [] }

  const columns = [{
    field: 'Nome',
    width: 250
  }, {
    field: 'Alcance',
    width: 250,
    renderCell: (params) => params.value.toLocaleString(),
  }, {
    field: 'Serviço',
    width: 250
  }]

  const rows = values.map((value, index) => {
    let rowValue = {
      id: index
    }
    for (var i in value) {
      rowValue[label[i]] = value[i]
    }
    return rowValue
  })

  return (
    <Card sx={{ height: '100%' }}>
      <Box padding="1rem">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box pt={2} pb={1} px={1}>
            <Typography variant="h6" textTransform="capitalize">
              {title}
            </Typography>
            <Typography color="text" variant="body2" fontWeight="light">
              {description}
            </Typography>
            <Divider />
            {labels}
          </Box>
          {loading && <CircularProgress />}
        </Box>

        <Box
          display="flex"
          variant="gradient"
          bgColor={color}
          borderRadius="lg"
          coloredShadow={color}
          py={2}
          pr={0.5}
          height="30rem"
          sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          {error ? <Box>
            <Typography>Erro ao carregar os dados</Typography>
            <Button onClick={handleLoadData}>Recarregar</Button>
          </Box> : rows.length > 0 ?
            <DataGrid
              rows={rows}
              columns={columns}
              rowLength={5}
              maxColumns={3}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
            /> : <Typography> {loading ? 'Carregando...' : 'Sem dados no período'}</Typography>}
        </Box>
      </Box>
    </Card>
  )
}

// Setting default values for the props of ReportsBarChart
ReportsTable.defaultProps = {
  color: 'white',
  description: '',
}

// Typechecking props for the ReportsBarChart
ReportsTable.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'white',
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ReportsTable
