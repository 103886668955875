import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";


// LTS React components
import { Box } from '@mui/material'

// LTS React context
import { useMaterialUIController, setLayout } from "@context";

function DashboardLayout({ children }) {
  const { pathname } = useLocation();
  const [, dispatch] = useMaterialUIController();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname, dispatch]);

  return (
    <Box sx={{ padding: 3, pt: 30 }}>
      {children}
    </Box>
  );
}

export default DashboardLayout;
