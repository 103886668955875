import { columns } from './columns'
import Card from '@mui/material/Card'
import { DataGrid } from '@mui/x-data-grid'
import { useContextSelector } from 'use-context-selector'
import { OcurrencesPageContext } from '@pages/ocurrences/context'
import { Box, CircularProgress, Typography } from '@mui/material'

function Table() {
  const total = useContextSelector(OcurrencesPageContext, (s) => s.total)
  const loading = useContextSelector(OcurrencesPageContext, (s) => s.loading)

  const ocurrences = useContextSelector(
    OcurrencesPageContext,
    (s) => s.ocurrences
  )

  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            Ocorrências
          </Typography>
          <Box display="flex" alignItems="center" lineHeight={0}>
            <Typography variant="body2" fontWeight="regular" color="text">
              <strong>{total} encontradas</strong> no período
            </Typography>
          </Box>
        </Box>
        {loading && <CircularProgress />}
      </Box>

      <Box sx={{ padding: 3 }}>
        <DataGrid
          rows={ocurrences}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </Box>
    </Card>
  )
}

export default Table
