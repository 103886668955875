import { Filter } from "@type/dashboard"

export type DashboardPageProps = {}
export type DashboardPageControllerProps = DashboardPageProps & {
  children: JSX.Element
}
export type DashboardPageContextProps = {
  loading: boolean
  value: number
  filter: string
  filters: Filter[]
  type: string
  reload: string
  setFilter: (filter: string) => void
  setType: (type: string) => void
  setReload: (reload: string) => void
}

export enum TypeOptions {
  "hour" = "OCORRENCIASPORQUALIFICACAOPORHORA",
  "days" = "OCORRENCIASPORDIA"
}