import { DashboardPageView } from './view'
import { DashboardPageProps } from './types'
import { DashboardPageController } from './controller'

export const DashboardPage = (props: DashboardPageProps): JSX.Element => {
  return (
    <DashboardPageController {...props}>
      <DashboardPageView />
    </DashboardPageController>
  )
}
