function configs(labels, datasets) {
  return {
    type: 'bar',
    data: {
      labels,
      datasets,
    },
    options: {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        datalabels: {
          display: false
        }
      },
      elements: {
        bar: {
          borderWidth: 2,
        }
      },
    }
  }
}

export default configs
