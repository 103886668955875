/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { Dashboard } from '@type/dashboard'
import { DashboardPageContext } from './context'
import { useEffect, useMemo, useState } from 'react'
import { DashboardPageControllerProps } from './types'
import { DashboardApi } from '@services/api/dashboard_api'

export const DashboardPageController = (
  props: DashboardPageControllerProps
): JSX.Element => {
  const api = useApi()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<Dashboard | null>(null)

  const handleLoadData = async () => {
    if (!props.filter) return

    try {
      setError(false)
      setLoading(true)

      const hashId = await api
        .instanceOf<DashboardApi>(DashboardApi)
        .generate_dash(props.filter.id)

      const data = await api
        .instanceOf<DashboardApi>(DashboardApi)
        .get_data(hashId, props.type)

      let dataJson = data.replaceAll("'", '"')
      const lastElement = dataJson.charAt(dataJson.length - 1)

      if (lastElement === ",") {
        dataJson = dataJson.slice(0, data.length - 1)
      }

      const content = JSON.parse(
        `[${dataJson}]`
      )

      setData({
        label: content[0],
        values: content.splice(1),
      } as Dashboard)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleLoadData()
  }, [props.filter, props.type, props.value])

  const state = useMemo(
    () => ({
      error,
      loading,
      data,
      handleLoadData
    }),
    [error, loading, data, handleLoadData]
  )

  return (
    <DashboardPageContext.Provider value={state}>
      {props.children}
    </DashboardPageContext.Provider>
  )
}
