import { ReportsPageView } from './view'
import { ReportsPageProps } from './types'
import { ReportsPageController } from './controller'

export const ReportsPage = (props: ReportsPageProps): JSX.Element => {
  return (
    <ReportsPageController {...props}>
      <ReportsPageView />
    </ReportsPageController>
  )
}
