import { Box } from '@mui/material'
import Footer from '@containers/Footer'
import { Outlet } from 'react-router-dom'
import DashboardLayout from '@containers/LayoutContainers/DashboardLayout'

export const WrapperComponent = (): JSX.Element => {
  return (
    <DashboardLayout>
      <Box py={3}>
        <Outlet />
      </Box>
      <Footer />
    </DashboardLayout>
  )
}
